import AuthService from "@core/services/auth/authService";
import {
  IBodyRequestValidateBrand,
  IGeralImportConfig,
  IGeralImportService,
  IResponseRequestValidateBrand,
  IResponseUploadBilling,
} from "@core/services/interfaces/geralEmplacamento/geralImport/IGeralImportService";
import { AxiosInstance, AxiosRequestConfig } from "axios";
import defaultConfig from "./geralImportDefaultConfig";

export default class GeralImportService
  extends AuthService
  implements IGeralImportService
{
  serviceGeralImportConfig: IGeralImportConfig;

  constructor(axiosIns: AxiosInstance, overrideConfig: Object) {
    super(axiosIns, overrideConfig);
    this.serviceGeralImportConfig = { ...defaultConfig, ...overrideConfig };
  }

  requestValidateBrand(
    body: IBodyRequestValidateBrand
  ): Promise<IResponseRequestValidateBrand> {
    return this.axiosIns.post(
      this.serviceGeralImportConfig.requestValidateBrandEndpoint,
      body
    );
  }

  requestGetFile(url: string): Promise<any> {
    return this.axiosIns.get(url);
  }

  requestUploadBilling(
    file: File,
    config?: AxiosRequestConfig
  ): Promise<IResponseUploadBilling> {
    const formData = new FormData();
    formData.append("files[]", file);

    return this.axiosIns.post(
      this.serviceGeralImportConfig.uploadBillingEndpoint,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        ...config
      }
    );
  }
}
